<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8">
        <v-card v-if="loading" elevation="3">
          <v-card-text>
            <v-sheet>
              <v-skeleton-loader
                type="card-heading, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, actions"
              ></v-skeleton-loader>
            </v-sheet>
          </v-card-text>
        </v-card>
        <order-form
          v-else
          v-model="formData"
          :title="
            orderId ? 'Editar orden de trabajo' : 'Nueva orden de trabajo'
          "
          @cancel="$router.back(-1)"
          @input="onSaveEvent"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { format } from "date-fns";
import EventBus from "@/util/eventbus";

import OrderForm from "@/components/forms/OrderForm";

export default {
  name: "OrderView",
  components: { OrderForm },
  data: () => ({
    loading: true,
    formData: {
      id: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      client: null,
      location: null,
      equipment: null,
      leader: null,
      workers: null,
      type: null,
      description: null,
      state: null,
    },
    orderId: null,
  }),
  created() {
    this.orderId = this.$route.params["id"];
    if (this.orderId) {
      this.$store.commit("LOADER", true);
      this.findOrder(this.orderId)
        .then((order) => {
          const startDate = new Date(order.startDate);
          const endDate = new Date(order.endDate);

          this.formData = Object.assign({}, order);
          this.formData.startDate = format(startDate, "yyyy-MM-dd");
          this.formData.startTime = format(startDate, "HH:mm");

          this.formData.endDate = format(endDate, "yyyy-MM-dd");
          this.formData.endTime = format(endDate, "HH:mm");
          this.loading = false;
        })
        .finally(() => {
          this.$store.commit("LOADER", false);
          this.loading = false;
        });
    } else {
      const currentDate = new Date();
      this.formData.startDate = format(currentDate, "yyyy-MM-dd");
      this.formData.startTime = format(currentDate, "HH:mm");
      this.formData.endDate = this.formData.startDate;
      this.formData.endTime = this.formData.startTime;
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("order", ["createOrder", "findOrder", "editOrder"]),
    onSaveEvent(data) {
      this.$store.commit("LOADER", true);
      if (this.orderId) {
        this.editOrder({ id: this.orderId, order: data })
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: "los datos han sido guardados",
              color: "success",
            });
            this.$router.push({ name: "order.list" });
          })
          .finally(() => this.$store.commit("LOADER", false));
      } else {
        this.createOrder(data)
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: "La orden de trabajo ha sido creada",
              color: "success",
            });
            this.$router.push({ name: "order.list" });
          })
          .finally(() => this.$store.commit("LOADER", false));
      }
    },
  },
};
</script>

<style scoped></style>
