<template>
  <v-card elevation="3">
    <v-card-title class="primary white--text">{{ title }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validation.valid" lazy-validation>
        <form-section title="Datos de la fecha/hora de inicio y fin" />
        <!-- Date section -->
        <div class="d-flex flex-column flex-md-row">
          <v-menu
            v-model="showStartDate"
            :close-on-content-click="false"
            :nudge-left="40"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value.startDate"
                v-bind="attrs"
                :rules="validation.startDateRules"
                append-icon="mdi-calendar-start"
                class="mr-md-2"
                label="Fecha de Inicio"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="value.startDate"
              :min="value.startDate"
              @change="(v) => (value.endDate = v)"
              @input="showStartDate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="startTime"
            v-model="showStartTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="value.startTime"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value.startTime"
                v-bind="attrs"
                :append-icon="'mdi-clock-start'"
                :rules="validation.startTimeRules"
                label="Hora de Inicio"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="showStartTime"
              v-model="value.startTime"
              format="24hr"
              full-width
              scrollable
              @change="(v) => (value.endTime = v)"
              @click:minute="$refs.startTime.save(value.startTime)"
            ></v-time-picker>
          </v-menu>
        </div>
        <div class="d-flex flex-column flex-md-row">
          <v-menu
            v-model="showEndDate"
            :close-on-content-click="false"
            :nudge-left="40"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value.endDate"
                v-bind="attrs"
                :rules="validation.endDateRules"
                append-icon="mdi-calendar-end"
                class="mr-md-2"
                label="Fecha de Fin"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="value.endDate"
              :min="value.startDate"
              @input="showEndDate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="endTime"
            v-model="showEndTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="value.endTime"
            max-width="290px"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value.endTime"
                v-bind="attrs"
                :append-icon="'mdi-clock-end'"
                :rules="validation.endTimeRules"
                label="Hora de fin"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="showEndTime"
              v-model="value.endTime"
              :min="minTime"
              format="24hr"
              full-width
              scrollable
              @click:minute="$refs.endTime.save(value.endTime)"
            ></v-time-picker>
          </v-menu>
        </div>
        <!-- End Date section -->
        <v-radio-group
          v-model="value.orderType"
          :rules="validation.orderType"
          row
        >
          <v-radio :value="1" label="Mantenimiento correctivo" />
          <v-radio :value="2" label="Mantenimiento preventivo" />
          <v-radio
            :value="3"
            label="Instalación de equipos"
            @change="handleAllEquipmentSelected"
          />
        </v-radio-group>

        <!--  Cliente      -->
        <v-autocomplete
          v-model="value.client"
          :items="
            Object.keys(dictionary.clients).map((v) => dictionary.clients[v])
          "
          :loading="isLoadingClients"
          :search-input.sync="clientSearch"
          :rules="validation.client"
          hide-no-data
          item-text="name"
          item-value="id"
          label="Cliente"
          placeholder="Ingrese el nombre de un cliente"
          prepend-icon="mdi-database-search"
          return-object
          @change="onChangeClient"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Ingrese el nombre de un
                <strong>Cliente</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>

        <!--  Office      -->
        <v-autocomplete
          v-model="value.office"
          :disabled="!value.client"
          :items="offices"
          :loading="isLoadingOffices"
          :search-input.sync="officeSearch"
          :rules="value.allEquipments ? validation.office : []"
          hide-no-data
          item-text="name"
          item-value="id"
          label="Local del cliente"
          placeholder="Ingrese un local"
          prepend-icon="mdi-office-building-marker"
          return-object
          @change="onChangeOffices"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Ingrese el nombre de un
                <strong>Cliente</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>

        <!--  Equipment      -->
        <div class="d-flex flex-row">
          <v-autocomplete
            v-model="value.equipment"
            :disabled="!value.office || value.allEquipments"
            :items="equipmentList"
            :loading="isLoadingEquipments"
            :search-input.sync="equipmentSearch"
            :rules="!value.allEquipments ? validation.equipment : []"
            :multiple="value.id === null"
            clearable
            hide-no-data
            hide-selected
            item-text="code"
            item-value="code"
            label="Equipo"
            :placeholder="
              value.id === null
                ? 'Ingrese los códigos de los equipos'
                : 'Ingrese el código del equipo'
            "
            prepend-icon="mdi-air-filter"
            class="mr-sm-3 mr-md-6"
            return-object
            @input="equipmentSearch = ''"
          >
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                class="white--text"
                close
                color="#005CAE"
                v-on="on"
                @click:close="removeEquipment(item)"
              >
                <router-link
                  :to="{ name: 'eq.edit', params: { id: item.code } }"
                  class="white--text"
                  target= '_blank'
                >
                  {{ item.code }}
                </router-link>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-checkbox
            v-if="value.orderType !== 3"
            v-model="value.allEquipments"
            :disabled="!value.office || value.orderType === 3"
            class="mr-sm-6 mr-md-12"
            @change="onAllEquipmentChange"
          >
            <template v-slot:label>
              <div>Todos los equipos</div>
            </template>
          </v-checkbox>
        </div>

        <!--  Jefe de Orden de trabajo      -->
        <v-autocomplete
          v-model="value.leader"
          :items="
            Object.keys(dictionary.employees).map(
              (k) => dictionary.employees[k]
            )
          "
          :loading="isLoadingLeader"
          :search-input.sync="leaderSearch"
          :rules="validation.leader"
          clearable
          hide-no-data
          item-text="name"
          item-value="id"
          label="Líder de Orden de la obra"
          placeholder="Ingrese el nombre líder de la obra"
          prepend-icon="mdi-account-supervisor"
          return-object
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <c-avatar
                  :src="data.item.picture"
                  :username="data.item.name"
                  class="my-2"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="data.item.email">{{
                  data.item.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <!--  Trabajadores      -->
        <v-autocomplete
          v-model="value.workers"
          :items="
            Object.keys(dictionary.employees).map(
              (k) => dictionary.employees[k]
            )
          "
          :loading="isLoadingLeader"
          :search-input.sync="workersSearch"
          :rules="validation.workers"
          clearable
          hide-no-data
          hide-selected
          item-text="name"
          item-value="id"
          label="Trabajadores"
          multiple
          placeholder="Agregue trabajadores a la obra"
          prepend-icon="mdi-account-search"
          return-object
          @input="workersSearch = ''"
        >
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-chip
              v-bind="attr"
              :input-value="selected"
              class="white--text"
              close
              color="#005CAE"
              v-on="on"
              @click:close="remove(item)"
            >
              {{ item.name }}
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <c-avatar
                  :src="data.item.picture"
                  :username="data.item.name"
                  class="my-2"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  data.item.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn depressed outlined @click="handleCancelForm">Cancelar</v-btn>
      <v-spacer />
      <v-btn class="primary" @click="handleSubmitForm">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import FormSection from "@/components/widgets/Section";
import CAvatar from "@/components/avatar/CAvatar";

export default {
  name: "OrderForm",
  components: { FormSection, CAvatar },
  props: {
    value: { type: Object, required: true },
    title: { type: String, default: "Nueva orden de trabajo" },
  },
  data: () => ({
    loading: true,
    showStartDate: false,
    showStartTime: false,
    showEndDate: false,
    showEndTime: false,
    orderType: null,

    validation: {
      valid: false,
      startDateRules: [(v) => !!v || "La fecha de inicio es requerida"],
      endDateRules: [(v) => !!v || "La fecha de fin es requerida"],
      startTimeRules: [(v) => !!v || "La hora de inicio es requerida"],
      endTimeRules: [(v) => !!v || "La hora de fin es requerida"],
      orderType: [(v) => !!v || "Seleccione un tipo de orden de trabajo"],
      office: [(v) => !!v || "Seleccione un local del cliente"],
      equipment: [(v) => !!v || "Seleccione equipos"],
      leader: [(v) => !!v || "Seleccione trabajador para líder de obra"],
      workers: [(v) => !!v || "Agregue trabajadores a la obra"],
    },

    offices: [],

    isLoadingClients: false,
    isLoadingOffices: false,
    isLoadingEquipments: false,
    isLoadingLeader: false,
    allEquipments: false,

    clientSearch: null,
    equipmentSearch: null,
    officeSearch: null,
    leaderSearch: null,
    workersSearch: null,
  }),
  computed: {
    ...mapState("library", ["dictionary"]),
    ...mapState("equipment", ["equipmentList"]),
    minTime() {
      return this.value.endDate > this.value.startDate
        ? "00:00"
        : this.value.startTime;
    },
  },
  watch: {
    clientSearch(val) {
      if (!val) return;

      val &&
        (typeof this.value.client === "undefined" ||
          this.value.client === null ||
          (this.value.client && val !== this.value.client.name)) &&
        this.fetchClientDebounced(val);
    },
    officeSearch(val) {
      if (!val) return;
      if (!(this.value.client && this.value.client.id)) return;

      (typeof this.value.office === "undefined" ||
        this.value.office === null ||
        (this.value.office && val !== this.value.office.name)) &&
        this.fetchClientOfficesDebounced(val);
    },
    leaderSearch(val) {
      if (!val) return;
      val &&
        (typeof this.value.leader === "undefined" ||
          this.value.leader === null ||
          (this.value.leader && val !== this.value.leader.name)) &&
        this.fetchLeaderDebounced(val);
    },
    workersSearch(val) {
      if (!val) return;
      this.fetchLeaderDebounced(val);
    },
    equipmentSearch(val) {
      if (!val) return;
      if (!(this.value.office && this.value.office.id)) return;
      if (!(this.value.client && this.value.client.id)) return;

      (typeof this.value.equipment === "undefined" ||
        this.value.equipment === null ||
        (this.value.equipment && val !== this.value.equipment.code)) &&
        this.fetchEquipmentListDebounced(val);
    },
  },
  created() {
    if (this.value.office) this.offices = [this.value.office];
  },
  methods: {
    ...mapActions("library", ["fetchCities", "fetchEmployees", "fetchClients"]),
    ...mapActions("client", ["clientOffices"]),
    fetchClientOfficesDebounced(val) {
      clearTimeout(this._searchTimerId);

      this._searchTimerId = setTimeout(() => {
        this.isLoadingOffices = true;
        this.$axios
          .get(`/api/client/${this.value.client.id}/office?PageSize=50`, {
            params: { query: val },
          })
          .then(({ data }) => (this.offices = data))
          .finally(() => (this.isLoadingOffices = false));
      }, 500); /* 500ms throttle */
    },
    fetchClientDebounced(val) {
      clearTimeout(this._searchTimerId);

      this._searchTimerId = setTimeout(() => {
        this.isLoadingClients = true;
        this.fetchClients({ query: val }).finally(
          () => (this.isLoadingClients = false)
        );
      }, 500); /* 500ms throttle */
    },
    fetchLeaderDebounced(val) {
      clearTimeout(this._searchTimerId);

      this._searchTimerId = setTimeout(() => {
        this.isLoadingLeader = true;
        this.fetchEmployees({ query: val }).finally(
          () => (this.isLoadingLeader = false)
        );
      }, 500); /* 500ms throttle */
    },
    fetchEquipmentListDebounced(val) {
      clearTimeout(this._searchTimerId);

      this._searchTimerId = setTimeout(() => {
        this.isLoadingEquipments = true;
        this.$axios
          .get(
            `/api/client/${this.value.client.id}/office/${this.value.office.id}/equipments?PageSize=50`,
            { params: { query: val } }
          )
          .then(({ data }) =>
            this.$store.commit("equipment/saveEquipmentList", {
              equipments: data,
            })
          )
          .finally(() => (this.isLoadingEquipments = false));
      }, 500); /* 500ms throttle */
    },
    remove(item) {
      const index = this.value.workers.indexOf(item);
      if (index >= 0) this.value.workers.splice(index, 1);
    },
    removeEquipment(item) {
      if (_.isArray(this.value.equipment)) {
        const index = this.value.equipment.indexOf(item);
        if (index >= 0) this.value.equipment.splice(index, 1);

        // Clean the array to trigger validation error
        if (this.value.equipment.length == 0) {
          this.value.equipment = null;
        }

        return;
      }

      this.value.equipment = null;
    },
    handleAllEquipmentSelected() {
      this.value.allEquipments = false;
    },
    handleCancelForm() {
      this.$emit("cancel", this.value);
    },
    handleSubmitForm() {
      if (!this.$refs.form.validate()) return;
      this.$emit("input", this.value);
    },
    onChangeOffices() {
      // Clean EquipmentList
      this.value.equipment = null;
      this.$store.commit("equipment/saveEquipmentList", { equipments: [] });
    },
    onChangeClient() {
      // Clean Offices
      this.offices = [];
      this.value.office = null;
    },
    onAllEquipmentChange(value) {
      if (value) this.value.equipment = null;
    },
  },
};
</script>

<style scoped>
</style>